
import common from '@/mixin/common.js'
import contact from '../contact/index.vue'
export default {
  name: 'r-about',
  components:{
	  contact
  },
  mixins: [common],
  data() {
    return {
      productBgImage: require('@/assets/about/about_bg.png'),
      propertyImgs: [
        require('@/assets/about/ic_europe.png'),
        require('@/assets/about/ic_china.png'),
        require('@/assets/about/ic_pct.png'),
      ],
    }
  },
  mounted() {
    // this.scrollToTop();
  },
}